import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { SmallGreenButton, disabledButtonStyles } from '../inputs/Button';
import { ApiSpace, ApiStationDetails } from '../../api/ApiTypes';
import { StreamContext } from './StreamContext';
import { useParams } from 'react-router-dom';
import equal from 'fast-deep-equal';

const StreamRoomForm = ({stationDetails}: {stationDetails: ApiStationDetails}) => {

    const { stationId } = useParams() as any;

    const streamContext = useContext(StreamContext);

    const spaces: ApiSpace[] = stationDetails.spaces;
    const openSpaces: ApiSpace[] = spaces.filter((space: ApiSpace) => space.isOpen);
    const closedSpaces: ApiSpace[] = spaces.filter((space: ApiSpace) => !space.isOpen);

    const allSpaceIds: string[] = spaces.map((space: ApiSpace) => { return space.id });

    const allSelected = (all: string[], checked: string[]) => {
        return all.every(elem => checked.includes(elem));
    }

    const defaultCheckedAll: boolean = allSelected(allSpaceIds, streamContext.stream.spaces);

    const { register, control, handleSubmit, getValues } = useForm();
    const [checkedAll, setCheckedAll] = useState(defaultCheckedAll);
    const [checkedSpaces, setCheckedSpaces] = useState(streamContext.stream.spaces);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const onSubmit = (data: any) => {
        let spaceIds = getValues('streamSpaces');
        if(!equal(spaceIds, streamContext.stream.spaces)){
            if(!spaceIds){
                spaceIds = [];
            } else if(typeof spaceIds == 'string'){
                spaceIds = [spaceIds];
            }
            streamContext.updateStreamSpaces(streamContext.stream.id, spaceIds, streamContext.stream.destination, streamContext.stream.latencyMode);
            setSaveDisabled(true);
        }
    };

    const handleAllClick = () => {
        const formState = getValues('streamSpaces') || [];
        const newState = !checkedAll;
        setCheckedAll(newState);
        if(newState == true){
            setCheckedSpaces(allSpaceIds);
        } else {
            setCheckedSpaces([]);
        }
    };

    const handleSpaceClick = () => {
        const formState = getValues('streamSpaces') || [];
        setCheckedSpaces(formState);
        setCheckedAll(allSelected(allSpaceIds, formState));
    };

    useEffect(() => {
        const formState = getValues('streamSpaces') || [];
        if(!equal(formState, streamContext.stream.spaces)){
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    },[checkedSpaces])

    return (
        <StreamForm onSubmit={handleSubmit(onSubmit)}>
            <AllButton>
                <AllCheckbox
                    name='allStreamSpaces'
                    type='checkbox'
                    value='all'
                    checked={checkedAll}
                    onChange={handleAllClick}
                    ref={register}
                />
                All
            </AllButton>
            {openSpaces.map((space) => {
                return (
                    <OpenRoomItem key={space.id}>
                        <Checkbox
                            name='streamSpaces'
                            type='checkbox'
                            value={space.id}
                            checked={checkedSpaces.includes(space.id)}
                            onChange={handleSpaceClick}
                            ref={register}
                        />
                        {space.title}
                    </OpenRoomItem>
                );
            })}
            {closedSpaces.map((space) => {
                return (
                    <ClosedRoomItem key={space.id}>
                        <Checkbox
                            name='streamSpaces'
                            type='checkbox'
                            value={space.id}
                            checked={checkedSpaces.includes(space.id)}
                            onChange={handleSpaceClick}
                            ref={register}
                        />
                        {space.title}
                    </ClosedRoomItem>
                )
            })}
            <Submit type='submit' className={saveDisabled ? 'disabled' : ''}>Save</Submit>
        </StreamForm>
    );
};

const StreamForm = styled.form`
    padding-left: 10px;
    padding-top: 15px;
    height: 100%;
    overflow-y: scroll;
`;

const AllButton = styled.label`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -10px;
    left: 0px;
    width: 73px;
    height: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-right: 10px;
    border-radius: 5px;
    background-color: #2f2d86;
    cursor: pointer;
    z-index: +1;
`;

const AllCheckbox = styled.input`
    position: relative;
    top: 1px;
    margin-right: 10px;
`;

const RoomItem = styled.label`
    font-size: 14px;
    cursor: pointer;
`;

const OpenRoomItem = styled(RoomItem)`
    font-weight: normal;
`;

const ClosedRoomItem = styled(RoomItem)`
    color: grey;
`;

const Checkbox = styled.input`
    position: relative;
    top: 1px;
    margin-right: 10px;
`;

const Submit = styled(SmallGreenButton)`
    position: absolute;
    top: -15px;
    left: 70%;
    border: none;
    &.disabled {
        ${disabledButtonStyles}
    }
`;

export default StreamRoomForm;
