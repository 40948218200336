import * as React from 'react';
import styled from 'styled-components/macro';

import { useContext } from 'react';
import { StreamContext } from './StreamContext';

const StreamLatencyMode = () => {
    const streamContext = useContext(StreamContext);

    const onLatencyModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.currentTarget;
        const latencyMode = select.value;
        streamContext.updateStreamSpaces(streamContext.stream.id, streamContext.stream.spaces, streamContext.stream.destination, latencyMode);
    }

    return (
        <StyledLowLatencyContainer>
            <StyledTitle>Latency Settings</StyledTitle>
            <StyledSelect
                className="stream-latency-select"
                defaultValue={ (streamContext.stream.latencyMode?? 'standard') }
                onChange={onLatencyModeChange}
                disabled={streamContext.stream?.details?.status === 'active'}
            >
                <StyledOption value={'standard'}>Standard (20-30 sec lag)</StyledOption>
                <StyledOption value={'reduced'}>Reduced (12-20 sec lag)</StyledOption>
                <StyledOption value={'low'}>Low (5-12 sec lag)</StyledOption>
            </StyledSelect>
        </StyledLowLatencyContainer>
    );
};

const StyledLowLatencyContainer = styled.div`
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 15px 15px 15px;
    margin-left: 510px;
`;

const StyledTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

const StyledSelect = styled.select`
    width: 50%;
    height: 34px;
    padding-left: 10px;
    margin-top: 15px;
    border-radius: 5px;
    font-weight: normal;
    font-size: 15px;
    color: var(--midnight);
`;

const StyledOption = styled.option`

`;

export default StreamLatencyMode;
