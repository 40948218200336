import { datadogLogs } from '@datadog/browser-logs';
import { env } from '../../utils/envLoader';

export class RemoteLogger {
    private logService = datadogLogs;

    public init(): typeof datadogLogs {
        this.logService.init({
            clientToken: env('DATA_DOG_CLIENT_TOKEN'),
            site: 'datadoghq.com',
            forwardErrorsToLogs: true,
            sampleRate: 100,
            env: 'dev',
            service: '2d-web'
        });

        return this.logService;
    }
}

export const remoteLogger = new RemoteLogger().init();
