import * as React from 'react';
import styled from 'styled-components/macro';
import StreamSource from './StreamSource';
import StreamSend from './StreamSend';
import StreamDestination from './StreamDestination';
import StreamLatencyMode from './StreamLatencyMode';
import { ApiStationDetails } from '../../api/ApiTypes';
import { useContext } from 'react';
import { StreamContext } from './StreamContext';
import { canAccessLiveStreamLatencySettings } from '../../permissions/stations';
import { useSelector } from 'react-redux';
import { getCurrentUserIsAdmin } from '../../store/selectors';

const StreamIO = () => {
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const streamContext = useContext(StreamContext);

    const canAccessLSLatencySettings = () => {
      if(isAdmin) return true;
      if(!streamContext.stationDetails?.station) return false;
      return canAccessLiveStreamLatencySettings(streamContext.stationDetails!.station);
    };

    return (
        <Container>
            <TopContainer>
            <StreamSource />
            <StreamSend />
            </TopContainer>
            <BottomContainer>
              <StreamDestination/>
              { canAccessLSLatencySettings() && <StreamLatencyMode/> }
            </BottomContainer>
        </Container>
    );
};

const BottomContainer = styled.div`
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 350px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #1b1a4d;
    font-size: 20px;
    font-weight: bold;
`;


export default StreamIO;
