import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { v4 as uuid } from 'uuid';
import {
    ApiSpaceEditableMetadata,
    ApiSpace,
    APITipHistory,
    APITip,
    EmbedRules,
    SpectateModeType,
    SpaceInviteDisplaySettings,
    SpaceMenuPermissions,
    SpaceMenuPermission
} from '../../api/ApiTypes';
import { AuthButton, TinyButton } from '../inputs/Button';
import LabeledToggle from '../inputs/LabeledToggle';
import UploadImage from '../inputs/UploadImage';
import ValidatedFormInput from '../inputs/ValidatedFormInput';
import { WhiteLinkText } from '../Typography';
import { useHistory } from 'react-router-dom';
import EnvironmentSelect from '../environments/EnvironmentSelect';
import useShowPageHelp from '../../hooks/useShowPageHelp';
import HelpText from '../HelpText';
import ShareModal from 'lit-share-modal-v3-react-17';
import { Select } from '../inputs/Select';
import { OpenCheckbox } from '../inputs/Checkbox';
import InputLabel from '../inputs/InputLabel';
import { DateUnit, FirebaseDate, ApiStation } from '../../api/ApiTypes';
const LitJsSdk = require('lit-js-sdk');
import equal from 'fast-deep-equal';
import InfoIcon from '../../images/atom-i-info-alt.svg';
import ReactTooltip from 'react-tooltip';
import { USDDollarFormat } from '../../utils/MoneyUtils';
import { GreyishBodyText } from '../Typography';
import { getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { getApi } from '../../api/api';
import { envAsBool } from '../../utils/envLoader';
import Divider from '../Divider';
import {
    canEmbed,
    hasSpaceCapacityLimit,
    canAccessAdvancedSettings,
    canLiveStream,
    hasOwnerRole,
    canHideBranding,
} from '../../permissions/stations';
import { canAdmin } from '../../permissions/users';
import { SmallButton } from '../inputs/Button';
import edit from '../../images/edit-icon.svg';

const SpaceForm = ({
    onSubmit,
    space,
    stationId
}: {
    onSubmit: (spaceData: ApiSpaceEditableMetadata) => Promise<any>;
    space?: ApiSpace;
    title: string;
    stationId: string;
}) => {
    const ENABLE_SPOTIFY_GATE = envAsBool('ENABLE_SPOTIFY_GATE');
    const defaultStationSpaceList = [{ label: 'None', value: '' }];
    const curFBDate = Math.floor(new Date().getTime() / 1000);
    const spectateDefaults = {
        spectate: {
            isEnabled: false,
            isInviteUiEnabled: true,
            isLockViewpointEnabled: false,
            activeMode: 'preview',
            preview: {
                modalTitle: 'Preview Mode',
                modalDescription: 'Join the conversion, explore',
                conversionButtonText: 'Join Experience',
                conversionButtonURL: ''
            },
            interactive: {
                modalTitle: 'Spectate Mode',
                modalDescription: 'Join the conversion, explore',
                conversionButtonText: 'Join Experience',
                conversionButtonURL: ''
            }
        }
    } as { [key: string]: any };
    const [capacity, setCapacity] = useState(15);
    const [environmentId, setEnvironmentId] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [description, setDescription] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isImageUrlModified, setIsImageUrlModified] = useState(false);
    const [title, setSpaceName] = useState('');
    const [discordWebhook, setDiscordWebhook] = useState('');
    const [uploading, setUploading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showingLitModal, setShowingLitModal] = useState(false);
    const [selectedConditions, setSelectedConditions] = useState(null as null | { [key: string]: any }[]);
    const [gateExpiration, setGateExpiration] = useState('-1,NEVER');
    const [gateCryptoDisplayMessage, setGateCryptoDisplayMessage] = useState('');
    const [gateSpotifyDisplayMessage, setGateSpotifyDisplayMessage] = useState('');
    const [gateWhitelistDisplayMessage, setGateWhitelistDisplayMessage] = useState('Request access from the space owner');
    const [gateWhitelistProvider, setGateWhitelistProvider] = useState('EMAIL_WHITELIST');
    const [gateWhitelistList, setGateWhitelistList] = useState([] as string[]);
    const [gateWhitelistListString, setGateWhitelistListString] = useState('');
    const [gateWhitelistApplyToHosts, setGateWhitelistApplyToHosts] = useState(false);
    const [gateSpotifyFollowId, setGateSpotifyFollowId] = useState('');
    const [addSpaceGate, setAddSpaceGate] = useState(false);
    const [addCryptoGate, setAddCryptoGate] = useState(false);
    const [addMusicGate, setAddMusicGate] = useState(false);
    const [addWhitelistGate, setAddWhitelistGate] = useState(false);
    const [gatePolicyChanged, setGatePolicyChanged] = useState(false);
    const [gateAuthSig, setGateAuthSig] = useState({} as { [key: string]: any });
    const [isAudioOnlySpace, setIsAudioOnlySpace] = useState(false);
    const [isThirdPartyAvatarAllowed, setIsThirdPartyAvatarAllowed] = useState(false);
    const [isTipOptIn, setIsTipOptIn] = useState(false);
    const [usdTipHistory, setUsdTipHistory] = useState<APITipHistory>({} as unknown as APITipHistory);
    const [upxTipHistory, setUpxTipHistory] = useState<APITipHistory>({} as unknown as APITipHistory);
    const [tippingEmail, setTippingEmail] = useState('');
    const [overflowSpaceId, setOverflowSpaceId] = useState('');
    const [stationSpacesList, setStationSpacesList] = useState(defaultStationSpaceList);
    const [isPrivate, setIsPrivate] = useState(false);
    const [isFeatured, isFeaturedSetter] = useState(false);
    const [isAnonymousDisallowed, setIsAnonymousDisallowed] = useState(false);
    const [isUnverifiedEmailDisallowed, setIsUnverifiedEmailDisallowed] = useState(false);
    const [isStreamViewerButtonDisabled, setIsStreamViewerButtonDisabled] = useState(false);
    const [isNowhereBrandingHidden, setIsNowhereBrandingHidden] = useState(false);
    const [isShowRoleTags, setIsShowRoleTags] = useState(false);
    const [isOrbitInfoPromptDisabled, setIsOrbitInfoPromptDisabled] = useState(false);
    const [newUserAccess, setNewUserAccess] = useState({
        isPaused: false,
        ruleSetAt: { _seconds: curFBDate } as FirebaseDate
    });
    const [gateSpotifyFollowType, setGateSpotifyFollowType] = useState('artist');
    const [spotifyLinks, setSpotifyLinks] = useState([] as string[]);
    const [embedRules, setEmbedRules] = useState<EmbedRules>({} as unknown as EmbedRules);
    const [isDoubleJumpDisabled, setIsDoubleJumpDisabled] = useState(false);
    const [isInWorldInviteUIDisabled, setIsInWorldInviteUIDisabled] = useState(false);
    const [isAISkyboxUIDisabledForNoneOwners, setIsAISkyboxUIDisabledForNoneOwners] = useState(false);
    const [uiVersion, setUiVersion] = useState('');
    const [stationDetails, setStationDetails] = useState<any>({} as unknown as { [key: string]: any });
    const [station, setStation] = useState<ApiStation>({} as unknown as ApiStation);
    const [spectatePoint, setSpectatePoint] = useState(undefined as undefined | { [key: string]: any });
    const [wallets, setWallets] = useState({ eos: '' } as { [key: string]: any });
    const [isAdvancedPermissionsShown, setIsAdvancedPermissionsShown] = useState(false);
    const [inviteDisplaySettings, setInviteDisplaySettings] = useState({
        host: { guest: true, spectate: false },
        member: { guest: true, spectate: false },
        spectator: { guest: false, spectate: true }
    });
    const [modeSettings, setModeSettings] = useState(spectateDefaults);
    const [inviteMemberDisplaySetting, setInviteMemberDisplaySetting] = useState('ALL');
    const [inviteHostDisplaySetting, setInviteHostDisplaySetting] = useState('ALL');
    const [menuPermissions, setMenuPermissions] = useState({
        Spaces: 'Everyone',
        Events: 'Disabled',
        Orbit: 'Everyone',
        Settings: 'Everyone',
        EditEntities: 'Hosts-Only',
        QuestionAndAnswer: 'Everyone',
        Chat: 'Everyone',
        PlayerList: 'Everyone',
    } as SpaceMenuPermissions);

    const currentUser = useSelector(getCurrentUser);

    const setNewImageUrl = (url: string) => {
        setImageUrl(url);
        setIsImageUrlModified(true);
    };

    const retreiveTipHistory = (spaceId: string, currency = 'USD') => {
        return getApi(`/tip/history/SPACE/${spaceId}?currency=${currency}`).then((atp) => {
            return atp.json();
        });
    };

    const retreiveUSDTipHistory = (spaceId: string) => {
        retreiveTipHistory(spaceId, 'USD')
            .then((atp) => {
                setUsdTipHistory(atp as APITipHistory);
            })
            .catch((e: Error) => {
                console.log('Could not get USD tip history with error: ', e);
            });
    };

    const retreiveUPXTipHistory = (spaceId: string) => {
        retreiveTipHistory(spaceId, 'UPX')
            .then((atp) => {
                setUpxTipHistory(atp as APITipHistory);
            })
            .catch((e: Error) => {
                console.log('x Could not get UPX tip history with error: ', e);
            });
    };

    const toggleTipping = (isAllowed: boolean) => {
        if (!isAllowed) {
            setTippingEmail('');
        } else {
            setTippingEmail(tippingEmail ? tippingEmail : currentUser.email);
        }
        setIsTipOptIn(isAllowed);
    };

    const cancel = (spaceId?: string) => {
        if (!spaceId) {
            history.goBack();
        } else {
            history.push(`/space/${spaceId}`);
        }
    };

    const generateGateResouce = () => {
        const path =
            window.location.pathname.split('/').slice(0, -1).join('/') +
            '/gate/' +
            Math.floor(Math.random() * 1000000000000).toString();
        return {
            baseUrl: window.location.host,
            path: path,
            orgId: 'NWR',
            role: '',
            extraData: 'Controls access to Nowhere Space'
        };
    };
    const [gateResource, setGateResource] = React.useState(generateGateResouce());

    const debugCryptoGate = false;

    const litNodeClient = new LitJsSdk.LitNodeClient();
    litNodeClient.connect();

    const showLitModal = () => {
        setShowingLitModal(true);
    };

    const round = (num: number, th = 100): number => {
        return Math.round(num * th) / th;
    };

    const cleanEmailList = (emails: string): string[] => {
      return emails
        .replace(/,\n/g, ',')
        .replace(/, \n/g, ',')
        .replace(/, /g, ',')
        .replace(/ /g, '')
        .replace('/\n/g', ',')
        .split(',')
        .reduce((acc, item) => {
          if(!item) return acc;
          return acc.concat(item.trim());
        }, [] as string[]);
    };

    const getSelectedGateOption = () => {
      if(addCryptoGate) return '1';
      if(addMusicGate) return '2';
      if(addWhitelistGate) return '3';
      return '1';
    }

    const gateTypeOptions = [
      { label: 'Crypto', value: '1' },
      { label: 'Spotify', value: '2' },
      { label: 'Whitelist', value: '3' },
    ];

    const gateExpirationValues = [
        { label: 'Only Once', value: '-1,NEVER' },
        { label: 'Every Day', value: '1,DAY' },
        { label: 'Every Week', value: '1,WEEK' },
        { label: 'Every Month', value: '1,MONTH' },
        { label: 'Every Year', value: '1,YEAR' },
    ];

    const spotifyFollowOptions = [
        { label: 'Follow an artist', value: 'artist,follow' },
        { label: 'Follow a playlist', value: 'playlist,follow' },
        { label: 'Follow a User', value: 'user,follow' },
        { label: 'Save an album', value: 'album,save' },
        { label: 'Save a track', value: 'track,save' },
    ];

    const gateWhitelistProviderOptions = [
      { label: 'Email', value: 'EMAIL_WHITELIST' },
      { label: 'User ID', value: 'USERID_WHITELIST' },
    ];

    const inviteDisplaySettingList = () => {
        const m = [
            { label: 'None', value: 'UI_DISABLED' },
            { label: 'Guest Only', value: 'FULL_GUEST' },
        ];

        if (!canAccessAdvancedSettings(station)) return m;
        return m.concat([
            { label: 'Spectator Only', value: 'SPEC_ONLY' },
            { label: 'GUEST & Spectator', value: 'ALL' },
        ]);
    };

    const getInviteDisplaySettingsMenuValue = (
        type: 'host' | 'member' | 'spectator',
        ids?: SpaceInviteDisplaySettings
    ): string => {
        ids = ids ?? inviteDisplaySettings;
        const ds = [ids[type].guest, ids[type].spectate].join(', ');
        switch (ds) {
            case 'false, false':
                return 'UI_DISABLED';
                break;
            case 'true, false':
                return 'FULL_GUEST';
                break;
            case 'false, true':
                return 'SPEC_ONLY';
            case 'true, true':
                return 'ALL';
                break;
            default:
                return 'ALL';
        }

        return 'ALL';
    };

    const getSpaceMenuOptions = (removeOptions?: string[]) => {
        const options = [
            { label: 'Everyone', value: 'Everyone' },
            { label: 'Hosts', value: 'Hosts-Only' },
            { label: 'Owner', value: 'Owner-Only' },
            { label: 'Disabled', value: 'Disabled' }
        ];
        //remove options
        if (removeOptions) {
            return options.filter((o) => !removeOptions.includes(o.value));
        }
        return options;
    };

    const setInviteDisplaySettingsMenuValue = (type: 'host' | 'member' | 'spectator', selectedValue = 'ALL') => {
        let value = { guest: false, spectate: false };
        switch (selectedValue) {
            case 'UI_DISABLED':
                value = { guest: false, spectate: false };
                break;
            case 'FULL_GUEST':
                value = { guest: true, spectate: false };
                break;
            case 'SPEC_ONLY':
                value = { guest: false, spectate: true };
                break;
            case 'ALL':
                value = { guest: true, spectate: true };
                break;
            default:
                value = { guest: true, spectate: true };
        }
        const ids = inviteDisplaySettings;
        ids[type] = value;
        ids.spectator = { ...ids.member, guest: false };
        setInviteDisplaySettings(ids);
    };

    const getSpectatePoint = async () => {
        if (!space) return undefined;
        const keyPrefix = 'spectatePoint_';
        const idName = await getApi(`/environment/${space?.environmentId}`)
            .then(async (resp) => {
                const isBadRequest = (resp.status > 399);
                const res = await resp.json();
                 if(isBadRequest) throw new Error(res.msg);
                return res.idName;
            })
            .catch((e: Error) => {
                return '__UNKNOWN__';
            });

        if (idName === '__UNKNOWN__') return undefined;

        const pointKey = `${keyPrefix}${idName}`;
        const pointValue = await getApi(`/space/${space!.id!}/state/${pointKey}`)
            .then(async (resp) => {
                const isBadRequest = (resp.status > 399);
                const res = await resp.json();
                if(isBadRequest) throw new Error(res.msg);
                return res;
            })
            .catch(() => {
                return '';
            });

        if (!pointValue?.value?.spectatePoint) return undefined;

        console.log({pointValue});

        return {
            X: pointValue.value.spectatePoint?._x,
            Y: pointValue.value.spectatePoint?._y,
            Z: pointValue.value.spectatePoint?._z,
            RX: pointValue.value.spectateRotation?._x,
            RY: pointValue.value.spectateRotation?._y,
            RZ: pointValue.value.spectateRotation?._z
        };
    };

    const fixLink = (link: string) => {
        if (link && !link.toLocaleLowerCase().startsWith('http')) {
            const protocol = link.toLocaleLowerCase().startsWith('localhost') ? 'http' : 'https';
            link = `${protocol}://${link}`;
        }
        return link;
    };

    const updateModeSettings = (keys: string[], value: any) => {
        console.log('updateModeSettings: ', { keys, value });
        const ms = modeSettings;
        const key = keys.slice(-1)[0];

        if (key === 'conversionButtonURL') value = fixLink(value);

        keys.reduce((acc: any, k: string) => {
            if (k === key) acc[k] = value;
            return acc[k];
        }, ms);

        setModeSettings({ ...modeSettings, ...ms });
    };

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    const showHelp = useShowPageHelp('editSpace');
    // const showHelp = true;
    const envHelpText =
        'Choose the environment for what you want to host: meetings, networking, performance, party, reunion...';
    const envHelpTextStyle = {};
    const envHelpTextInnerStyle = {
        width: '300px',
        right: '200px',
        top: '26px'
    };
    const discordHelpText = 'Connect your Discord to notify your community anytime someone enters this space.';
    const discordHelpTextInnerStyle = {
        width: '300px',
        right: '20px',
        bottom: '-3px'
    };

    const handleCapacityChange = (e: any) => {
        const val = e.target.value;
        const spcl = hasSpaceCapacityLimit(station);
        const sc = val > spcl ? spcl : val < 0 ? 0 : val;
        setCapacity(sc);
    };

    const getStationDetails = async (stationId: string) => {
        const sd = await getApi(`/station/${stationId}`).then(async (ss) => {
            return await ss.json();
        });
        setStationDetails(sd);
    };

    const getSpaceInvite = () => {
        const spaceId = space?.id ?? '<spaceId>';
        const inviteCode = stationDetails?.station?.inviteCode;
        if (!inviteCode) return '';
        return `${window.location.protocol}//${window.location.host}/invite/${inviteCode}?spaceId=${spaceId}`;
    };

    const applySpaceInviteToActiveSpecate = (activeMode?: string) => {
        activeMode = activeMode ?? modeSettings.spectate.activeMode;
        const spaceInvite = getSpaceInvite();
        updateModeSettings(['spectate', activeMode!, 'conversionButtonURL'], spaceInvite);
    };

    const resetSpectate = () => {
        updateModeSettings(['spectate'], { ...spectateDefaults.spectate, isEnabled: true });
    };

    const createStationSpacesList = async (thisSpace: undefined | ApiSpace) => {
        try {
            const sl = stationDetails.spaces
                .filter((s: ApiSpace) => s.id !== thisSpace?.id ?? '<--Empty-->')
                .map((s: ApiSpace) => {
                    return { label: s.title, value: s.id! };
                });
            const fsl = [
                {
                    label: 'Station Distribution',
                    value: '_station_dist:__SEND_USER_TO_STATION_DISTRIBUTION__'
                }, {
                    label: 'Station Overview',
                    value: `_station:${thisSpace?.stationId ?? stationDetails?.station?.id ?? '__PARENT__'}`
                }, {
                    label: 'Spectate',
                    value: '_spectate:__SEND_USER_TO_SPECTATE__'
                }
            ].concat(sl);
            setStationSpacesList(defaultStationSpaceList.concat(fsl));
        } catch (e) {
            console.log('Could not construct space list with error: ', e);
        }
    };

    const userAccessRestictionsAnonToggleSetter = (value: boolean) => {
        setIsAnonymousDisallowed(value);
        if (!value) setIsUnverifiedEmailDisallowed(false);
    };

    const userAccessRestictionsVerifiedEmailToggleSetter = (value: boolean) => {
        setIsUnverifiedEmailDisallowed(value);
        if (value) setIsAnonymousDisallowed(true);
    };

    const cleanIds = (ids: string[]): string[] => {
        const cIds = ids.map((id) => {
            const cid = id.split('?')[0];
            return cid.indexOf('/') > -1 ? cid.split('/').reverse()[0] : cid;
        });
        setGateSpotifyFollowId(cIds.join(','));
        return cIds;
    };

    const updateSpotifyLinks = (event?: any, fType?: string, aIds?: string) => {
        const followType = fType ? fType! : gateSpotifyFollowType;
        const unCleanIds = aIds
            ? aIds!.split(',').map((id: string) => id.trim())
            : gateSpotifyFollowId.split(',').map((id: string) => id.trim());
        const ids = cleanIds(unCleanIds);

        const baseLink = 'https://open.spotify.com';
        const links = ids.reduce((acc, id) => {
            const assetType = followType.split(',')[0];
            return acc.concat(`${baseLink}/${assetType}/${id}`);
        }, [] as string[]);

        setSpotifyLinks(!followType ? [] : links);
        if (event) updateSpotifyDisplayMessage(fType, ids.join(','));
    };

    const updateSpotifyDisplayMessage = async (fType?: string, aIds?: string) => {
        const followType = fType ? fType : gateSpotifyFollowType;
        const ids = aIds ? aIds : gateSpotifyFollowId;

        if (!followType || !ids) {
            setGateSpotifyDisplayMessage('');
            return;
        }

        getApi(`/spotify/data?type=${followType}&ids=${ids}`).then(async (resp) => {
            const res = await resp.json();
            const t = gateSpotifyFollowType.split(',')[0];
            let msg = '';
            const msgSufix = ' on Spotify.';
            const msgPrefix = 'Please ';
            switch (t) {
                case 'track':
                case 'album':
                    msg = res.reduce((acc: string, r: { [key: string]: any }) => {
                        const name = r?.body?.name ?? 'unknown';
                        const artists = (r?.body?.artists ?? [{ name: 'unknown' }]).reduce(
                            (ac: string, a: { [key: string]: any }) => {
                                return !ac ? `"${a.name}"` : ac + `, "${a.name}"`;
                            },
                            ''
                        );
                        return !acc ? `save "${name}" by ${artists}` : acc + ` & "${name}" by ${artists}`;
                    }, '');
                    break;
                case 'user':
                    msg = res.reduce((acc: string, r: { [key: string]: any }) => {
                        const name = r?.body?.display_name ?? 'unknown';
                        return !acc ? `follow "${name}"` : acc + ` & "${name}"`;
                    }, '');
                    break;
                case 'artist':
                    msg = res.reduce((acc: string, r: { [key: string]: any }) => {
                        const name = r?.body?.name ?? 'unknown';
                        return !acc ? `follow "${name}"` : acc + ` & "${name}"`;
                    }, '');
                    break;
                case 'playlist':
                    msg = res.reduce((acc: string, r: { [key: string]: any }) => {
                        const name = r?.body?.name ?? 'unknown';
                        const creator = r?.body?.owner?.display_name ?? 'unknown';
                        return !acc ? `follow "${name}" by "${creator}"` : acc + ` & "${name}" by "${creator}"`;
                    }, '');
                    break;
                default:
                    msg = '';
            }

            console.log('Message: ', msgPrefix + msg + msgSufix);

            msg ? setGateSpotifyDisplayMessage(msgPrefix + msg + msgSufix) : setGateSpotifyDisplayMessage(msg);
        });
    };

    useEffect(() => {
        if (space?.environmentId) setEnvironmentId(space.environmentId);
        if (space?.title) setSpaceName(space.title);
        if (space?.capacity) setCapacity(space.capacity);
        if (space?.imageUrl) setImageUrl(space.imageUrl);
        if (space?.subtitle) setSubtitle(space.subtitle);
        if (space?.description) setDescription(space.description);
        if (space?.discordWebhook) setDiscordWebhook(space.discordWebhook);
        if (space?.isActive) setIsActive(space.isActive);
        if (space?.overflowSpaceId) setOverflowSpaceId(space.overflowSpaceId);
        if (space?.userAccessRestictions?.isAnonymousDisallowed)
            setIsAnonymousDisallowed(space?.userAccessRestictions?.isAnonymousDisallowed ? true : false);
        if (space?.userAccessRestictions?.isUnverifiedEmailDisallowed)
            setIsUnverifiedEmailDisallowed(space?.userAccessRestictions?.isUnverifiedEmailDisallowed ? true : false);
        if (space?.userAccessRestictions?.newUserAccess) setNewUserAccess(space.userAccessRestictions.newUserAccess);
        if (space?.gatedAccess?.policy) setSelectedConditions(space!.gatedAccess!.policy!);
        if (space?.gatedAccess?.expire)
            setGateExpiration(`${space!.gatedAccess!.expire!.ammount},${space!.gatedAccess!.expire!.unit}`);
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'CRYPTO_ASSETS')
            setGateCryptoDisplayMessage(space!.gatedAccess!.displayMessage);
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'MUSIC_ASSETS')
            setGateSpotifyDisplayMessage(space!.gatedAccess!.displayMessage);
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'LIST_ASSETS')
            setGateWhitelistDisplayMessage(space!.gatedAccess!.displayMessage);
        if (space?.gatedAccess?.isActive) setAddSpaceGate(true);
        if (space?.gatedAccess?.isActive && space?.gatedAccess?.type === 'CRYPTO_ASSETS') setAddCryptoGate(true);
        if (space?.gatedAccess?.isActive && space?.gatedAccess?.type === 'MUSIC_ASSETS') setAddMusicGate(true);
        if (space?.gatedAccess?.isActive && space?.gatedAccess?.type === 'LIST_ASSETS') setAddWhitelistGate(true);
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'LIST_ASSETS')
            setGateWhitelistProvider(space?.gatedAccess?.provider?? 'EMAIL_WHITELIST')
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'LIST_ASSETS') {
            setGateWhitelistList(space?.gatedAccess?.policy[0]?.list?? []);
            setGateWhitelistListString( (space?.gatedAccess?.policy[0]?.list?? []).join(', ') );
        }
        if (space?.gatedAccess?.displayMessage && space?.gatedAccess?.type === 'LIST_ASSETS')
            setGateWhitelistApplyToHosts(space?.gatedAccess?.policy[0]?.isAppliedToHosts?? false)
        if (space?.gatedAccess?.policy[0]?.type) setGateSpotifyFollowType(space!.gatedAccess!.policy[0]!.type);
        if (space?.gatedAccess?.policy[0]?.id) setGateSpotifyFollowId(space!.gatedAccess!.policy[0]!.id);
        if (space?.gatedAccess?.resource) setGateResource(space!.gatedAccess!.resource);
        if (space?.isPrivate) setIsPrivate(space.isPrivate);
        if (space?.featured && space.featured > 0) isFeaturedSetter(true);
        if (space?.tippingEmail) setTippingEmail(space.tippingEmail);
        if (space?.isAudioOnlySpace) setIsAudioOnlySpace(true);
        if (space?.isThirdPartyAvatarAllowed) setIsThirdPartyAvatarAllowed(true);
        if (space?.isDoubleJumpDisabled) setIsDoubleJumpDisabled(true);
        if (space?.isInWorldInviteUIDisabled) setIsInWorldInviteUIDisabled(true);
        if (space?.isStreamViewerButtonDisabled) setIsStreamViewerButtonDisabled(true);
        if (space?.isOrbitInfoPromptDisabled) setIsOrbitInfoPromptDisabled(true);
        if (space?.isNowhereBrandingHidden) setIsNowhereBrandingHidden(true);
        if (space?.isShowRoleTags) setIsShowRoleTags(true);
        if (space?.isAISkyboxUIDisabledForNoneOwners) setIsAISkyboxUIDisabledForNoneOwners(true);
        if (space?.modeSettings) setModeSettings({ ...modeSettings, ...space!.modeSettings! });
        if (space?.embedRules) setEmbedRules(space.embedRules);
        if (space?.wallets) setWallets(space?.wallets);
        if (space?.inviteDisplaySettings !== undefined) {
            const ids = space!.inviteDisplaySettings!;
            setInviteDisplaySettings(ids);
            setInviteMemberDisplaySetting(getInviteDisplaySettingsMenuValue('member', ids));
            setInviteHostDisplaySetting(getInviteDisplaySettingsMenuValue('host', ids));
        }
        if (space?.uiVersion) setUiVersion(space.uiVersion);
        if (space?.id) retreiveUSDTipHistory(space.id!);
        if (space?.id) retreiveUPXTipHistory(space.id!);
        if (space ?? false) {
            setIsTipOptIn(space?.tippingEmail ? true : false);
        } else {
            toggleTipping(true);
        }

        if (space?.menuPermissions) setMenuPermissions({ ...space.menuPermissions });

        getStationDetails(stationId);

        if (space) getSpectatePoint().then((s) => setSpectatePoint(s));

        updateSpotifyLinks(
            undefined,
            space?.gatedAccess?.policy ? space!.gatedAccess!.policy[0]!.type : undefined,
            space?.gatedAccess?.policy ? space!.gatedAccess!.policy[0]!.id : undefined
        );
    }, [space]);

    useEffect(() => {
        setInviteDisplaySettingsMenuValue('host', inviteHostDisplaySetting);
        setInviteDisplaySettingsMenuValue('member', inviteMemberDisplaySetting);
    }, [inviteHostDisplaySetting, inviteMemberDisplaySetting]);

    useEffect(() => {
        //alert('isEnabled:' + JSON.stringify(modeSettings?.spectate?.isEnabled));
        //const iamp = modeSettings?.spectate?.activeMode === 'preview';
        //alert(JSON.stringify(modeSettings?.spectate?.activeMode) + ` isPreview: ${iamp}`);
        //setIsSpectateActiveModePreview(iamp);
    }, [modeSettings]);

    useEffect(() => {
        createStationSpacesList(space);
        setStation(stationDetails.station);
        if (!space) setCapacity(hasSpaceCapacityLimit(stationDetails.station));

        if (stationDetails.station) {
            if (!canLiveStream(stationDetails.station)) {
                setIsStreamViewerButtonDisabled(true);
            }

            if (!canAccessAdvancedSettings(stationDetails.station)) {
                if (inviteMemberDisplaySetting === 'ALL') {
                    setInviteMemberDisplaySetting('FULL_GUEST');
                } else if (inviteMemberDisplaySetting === 'SPEC_ONLY') {
                    setInviteMemberDisplaySetting('None');
                }
            }

            if (!canAccessAdvancedSettings(stationDetails.station)) {
                if (inviteHostDisplaySetting === 'ALL') {
                    setInviteHostDisplaySetting('FULL_GUEST');
                } else if (inviteHostDisplaySetting === 'SPEC_ONLY') {
                    setInviteHostDisplaySetting('None');
                }
            }
        }
    }, [stationDetails]);

    const submitWithSpaceData = async () => {
        if (showingLitModal) return;
        if (submitting) return;
        setSubmitting(true);

        const spaceData: ApiSpaceEditableMetadata = {
            capacity,
            subtitle,
            description,
            environmentId,
            isActive,
            title: title,
            imageUrl: imageUrl,
            discordWebhook,
            gatedAccess: getGatedAccessObj(),
            tippingEmail: tippingEmail,
            overflowSpaceId: overflowSpaceId,
            userAccessRestictions: {
                isAnonymousDisallowed,
                isUnverifiedEmailDisallowed,
                newUserAccess
            },
            isPrivate,
            featured: isFeatured ? 1 : 0,
            isAudioOnlySpace,
            isThirdPartyAvatarAllowed,
            isDoubleJumpDisabled,
            isAISkyboxUIDisabledForNoneOwners,
            isInWorldInviteUIDisabled,
            isStreamViewerButtonDisabled,
            isOrbitInfoPromptDisabled,
            isNowhereBrandingHidden,
            isShowRoleTags,
            inviteDisplaySettings,
            embedRules,
            uiVersion,
            modeSettings,
            wallets,
            menuPermissions
        };

        if (spaceData?.modeSettings?.spectate?.preview?.conversionButtonURL === '') {
            spaceData!.modeSettings!.spectate!.preview!.conversionButtonURL = getSpaceInvite();
        }

        if (spaceData?.modeSettings?.spectate?.interactive?.conversionButtonURL === '') {
            spaceData!.modeSettings!.spectate!.interactive!.conversionButtonURL = getSpaceInvite();
        }

        if (!spaceData?.modeSettings?.spectate.isInviteUiEnabled) {
            spaceData!.inviteDisplaySettings!.spectator = { guest: false, spectate: false };
        } else {
            spaceData!.inviteDisplaySettings!.spectator = { ...spaceData!.inviteDisplaySettings!.member, guest: false };
        }

        if (!isImageUrlModified) delete spaceData.imageUrl;

        if (gatePolicyChanged && addCryptoGate) {
            const litNodeClient = new LitJsSdk.LitNodeClient();
            await litNodeClient.connect();
            const chain = spaceData!.gatedAccess!.policy[0].chain;
            const resourceId = spaceData!.gatedAccess!.resource;
            const savingCondition = {
                unifiedAccessControlConditions: selectedConditions,
                chain: chain,
                authSig: gateAuthSig,
                resourceId: resourceId,
                permanant: false
            };

            await litNodeClient.saveSigningCondition(savingCondition);
        }

        await onSubmit(spaceData).then(() => setSubmitting(false));
    };

    const getGatedAccessObj = () => {
        if (addCryptoGate) {
            if (!selectedConditions) return undefined;

            return {
                type: 'CRYPTO_ASSETS',
                provider: 'LIT_PROTOCOL',
                policy: selectedConditions!,
                expire: {
                    ammount: parseInt(gateExpiration.split(',')[0]),
                    unit: gateExpiration.split(',')[1] as DateUnit
                },
                displayMessage: gateCryptoDisplayMessage,
                resource: gateResource,
                isActive: addSpaceGate && addCryptoGate
            };
        }

        if (addMusicGate) {
            return {
                type: 'MUSIC_ASSETS',
                provider: 'SPOTIFY',
                policy: [
                    {
                        type: gateSpotifyFollowType,
                        id: gateSpotifyFollowId
                    }
                ],
                expire: {
                    ammount: parseInt(gateExpiration.split(',')[0]),
                    unit: gateExpiration.split(',')[1] as DateUnit
                },
                displayMessage: gateSpotifyDisplayMessage,
                resource: gateResource,
                isActive: addSpaceGate && addMusicGate
            };
        }

        if (addWhitelistGate) {
            return {
                type: 'LIST_ASSETS',
                provider: gateWhitelistProvider,
                policy: [
                    {
                        id: uuid(),
                        isAppliedToHosts: gateWhitelistApplyToHosts,
                        list: cleanEmailList(gateWhitelistListString),
                    }
                ],
                expire: {
                    ammount: parseInt(gateExpiration.split(',')[0]),
                    unit: gateExpiration.split(',')[1] as DateUnit
                },
                displayMessage: gateWhitelistDisplayMessage,
                resource: gateResource,
                isActive: addSpaceGate && addWhitelistGate
            };
        }
    };

    const DiscordLabelElement = () => {
        const anchor = (
            <a
                href="https://urnowhere.notion.site/urnowhere/Discord-Channel-Invite-Bot-7d51b8472ae242c788cfbd85f4dcb8e8"
                target="blank"
                // rel='noopener noreferrer'
                style={{ color: 'var(--tennis-green)' }}
            >
                {`What's this?`}
            </a>
        );

        return <label htmlFor="discordWebhook">Connect to your Discord Channel :: {anchor}</label>;
    };

    const LitModal = () => {
        if (!showingLitModal) return null;
        return (
            <div className={'lit-share-modal'}>
                <ShareModal
                    onClose={() => {
                        setShowingLitModal(false);
                    }}
                    onUnifiedAccessControlConditionsSelected={async (uacc: any) => {
                        setShowingLitModal(false);

                        const accessControlConditions = uacc.unifiedAccessControlConditions;
                        const chain = uacc.chains[0];
                        const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain: chain }).catch((e: Error) => {
                            console.log('Error Getting Gate Auth Sig: ', e);
                            console.log('Unified Access Control Conditions: ', uacc);
                            alert(
                                'Something went wrong. Please make sure that the selected blockchain is available in your wallet provider'
                            );
                        });

                        //console.log("authSig: ", authSig);

                        if (authSig) {
                            setGateAuthSig(authSig);
                            setSelectedConditions(accessControlConditions);
                            setGateResource(generateGateResouce());
                            setGatePolicyChanged(true);
                        }
                    }}
                />
            </div>
        );
    };

    return (
        <Form
            id="signup-Form"
            name="wf-form-signup-Form"
            data-name="signup Form"
            className="form-2"
            onSubmit={handleSubmit(submitWithSpaceData)}
        >
            <FormColumns>
                <LeftColumn>
                    <UploadImage
                        onUpload={(u: string) => setNewImageUrl(u)}
                        existingImage={space?.imageUrl}
                        uploading={uploading}
                        setUploading={setUploading}
                    />
                    <PrivacyToggleContainer>
                        <PrivacyLabel>Visibility</PrivacyLabel>
                        <LabeledToggle
                            leftLabel="UNLISTED"
                            rightLabel="PUBLIC"
                            value={space?.isPrivate ?? true}
                            changeHandler={(value) => setIsPrivate(value)}
                        />
                    </PrivacyToggleContainer>
                    {canAdmin(currentUser) && (
                        <FeaturedCheckboxContainer>
                            <FeaturedLabel>Featured</FeaturedLabel>
                            <OpenCheckbox
                                errors={errors}
                                name="isFeatured"
                                checked={isFeatured}
                                onChange={() => isFeaturedSetter(!isFeatured)}
                            />
                        </FeaturedCheckboxContainer>
                    )}
                    <CapacityInputContainer>
                        <CapacityLabel>Capacity</CapacityLabel>
                        <ValidatedFormInput
                            labelText=""
                            className="w-input"
                            errors={errors}
                            id="capacity"
                            name="spaceCapacity"
                            onChange={handleCapacityChange}
                            type="number"
                            validation={register({ required: 'Capacity is required' })}
                            value={capacity}
                            style={{ width: '78px' }}
                        />
                    </CapacityInputContainer>
                    <OverFlowSpaceContainer>
                        <label htmlFor="overflowSpace">
                            Overflow To
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="When this space reaches capacity users will be automatically redirected to the overflow space, mode or location."
                                src={InfoIcon}
                            />
                        </label>
                        <Select
                            onChange={(value) => setOverflowSpaceId(value)}
                            value={overflowSpaceId}
                            options={stationSpacesList}
                            spClassName="noTranslate"
                        />
                    </OverFlowSpaceContainer>

                    <Divider />
                    <OverFlowSpaceContainer>
                        <h4>Access Settings</h4>
                    </OverFlowSpaceContainer>

                    <UserAccessRestictionsAnonContainer>
                        <UserAccessRestictionsLabel>
                            Allow Anonymous Guests
                            <ReactTooltip effect="solid" />
                            <InactiveInfo data-tip="Require all guests to have an account." src={InfoIcon} />
                        </UserAccessRestictionsLabel>
                        <OpenCheckbox
                            errors={errors}
                            name="isAnonAllowed"
                            checked={isAnonymousDisallowed ? false : true}
                            onChange={() => userAccessRestictionsAnonToggleSetter(!isAnonymousDisallowed)}
                        />
                    </UserAccessRestictionsAnonContainer>
                    <UserAccessRestictionsVerifiedContainer>
                        <UserAccessRestictionsLabel>
                            Allow Unverified Guests
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Require all guests to verify their email to access"
                                src={InfoIcon}
                            />
                        </UserAccessRestictionsLabel>
                        <OpenCheckbox
                            errors={errors}
                            name="isUnverifiedEmailDisallowed"
                            checked={isUnverifiedEmailDisallowed ? false : true}
                            onChange={() =>
                                userAccessRestictionsVerifiedEmailToggleSetter(!isUnverifiedEmailDisallowed)
                            }
                        />
                    </UserAccessRestictionsVerifiedContainer>

                    <OverFlowSpaceContainer>
                        <label htmlFor="guestInWorldInviteSettings">
                            Guest In-World Invite
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Select the type of invites that should be shared with guests"
                                src={InfoIcon}
                            />
                        </label>
                        <Select
                            onChange={(value) => setInviteMemberDisplaySetting(value)}
                            value={inviteMemberDisplaySetting}
                            options={inviteDisplaySettingList()}
                            spClassName="noTranslate"
                        />
                    </OverFlowSpaceContainer>

                    <OverFlowSpaceContainer>
                        <label htmlFor="guestInWorldInviteSettings">
                            Host In-World Invite
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="Select the type of invites that should be shared with hosts"
                                src={InfoIcon}
                            />
                        </label>
                        <Select
                            onChange={(value) => setInviteHostDisplaySetting(value)}
                            value={inviteHostDisplaySetting}
                            options={inviteDisplaySettingList()}
                            spClassName="noTranslate"
                        />
                    </OverFlowSpaceContainer>
                    {canLiveStream(stationDetails.station) && (
                        <UserAccessRestictionsAnonContainer style={{ marginTop: '-2px' }}>
                            <UserAccessRestictionsLabel>
                                Enable Stream Viewer Button
                                <ReactTooltip effect="solid" />
                                <InactiveInfo
                                    data-tip="Shows buttons to guests in-world & on Space cards"
                                    src={InfoIcon}
                                />
                            </UserAccessRestictionsLabel>
                            <OpenCheckbox
                                errors={errors}
                                name="isStreamViewerButtonDisabled"
                                checked={isStreamViewerButtonDisabled ? false : true}
                                onChange={() => setIsStreamViewerButtonDisabled(!isStreamViewerButtonDisabled)}
                            />
                        </UserAccessRestictionsAnonContainer>
                    )}

                    {envAsBool('ENABLE_ADDTIONAL_ADVANCED_HIDE_UI_OPTIONS') && canAccessAdvancedSettings(stationDetails.station) && (
                        <UserAccessRestictionsAnonContainer style={{ marginTop: '-2px' }}>
                            <UserAccessRestictionsLabel>
                                Enable Orbit Info Prompt
                                <ReactTooltip effect="solid" />
                                <InactiveInfo
                                    data-tip="If a player hasn’t filled out their Orbit card with contact info, Nowhere periodically asks them again, uncheck to skip this."
                                    src={InfoIcon}
                                />
                            </UserAccessRestictionsLabel>
                            <OpenCheckbox
                                errors={errors}
                                name="isOrbitInfoPromptDisabled"
                                checked={isOrbitInfoPromptDisabled ? false : true}
                                onChange={() => setIsOrbitInfoPromptDisabled(!isOrbitInfoPromptDisabled)}
                            />
                        </UserAccessRestictionsAnonContainer>
                    )}

                    {canAccessAdvancedSettings(station) && (
                        <>
                            <Divider />
                            <OverFlowSpaceContainer>
                                <h4>3D Settings</h4>
                            </OverFlowSpaceContainer>
                            <br />
                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Allow 3rd Party Avatars
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="Allows guests with 3rd party avatars to use them in this space"
                                        src={InfoIcon}
                                    />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isThirdPartyAvatarAllowed"
                                    checked={isThirdPartyAvatarAllowed ? true : false}
                                    onChange={() => setIsThirdPartyAvatarAllowed(!isThirdPartyAvatarAllowed)}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Audio only
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="This space will be audio only and have no video"
                                        src={InfoIcon}
                                    />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isAudioOnlySpace"
                                    checked={isAudioOnlySpace ? true : false}
                                    onChange={() => setIsAudioOnlySpace(!isAudioOnlySpace)}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Disable Double Jump
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="This space will prevent players from double jumping"
                                        src={InfoIcon}
                                    />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isDoubleJumpDisabled"
                                    checked={isDoubleJumpDisabled ? true : false}
                                    onChange={() => setIsDoubleJumpDisabled(!isDoubleJumpDisabled)}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            {/*                        <UserAccessRestictionsVerifiedContainer>
                          <UserAccessRestictionsLabel>
                              Disable AI Skybox UI For Non-Owners
                              <ReactTooltip effect="solid" />
                              <InactiveInfo data-tip="Prevent the AI skybox UI from showing in world for non-owners" src={InfoIcon} />
                          </UserAccessRestictionsLabel>
                          <OpenCheckbox
                              errors={errors}
                              name="isAISkyboxUIDisabledForNoneOwners"
                              checked={isAISkyboxUIDisabledForNoneOwners? true: false}
                              onChange={() => setIsAISkyboxUIDisabledForNoneOwners(!isAISkyboxUIDisabledForNoneOwners)}
                          />
                      </UserAccessRestictionsVerifiedContainer> */}

                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Follow Button
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo data-tip="Show the Follow UI in world" src={InfoIcon} />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isInWorldInviteUIDisabled"
                                    checked={isInWorldInviteUIDisabled ? false : true}
                                    onChange={() => setIsInWorldInviteUIDisabled(!isInWorldInviteUIDisabled)}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Show Role Tags
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo data-tip="Adds an indicator above players to display their Host status, or show any custom Role Tags assigned (Available through API only)." src={InfoIcon} />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isShowRoleTags"
                                    checked={isShowRoleTags ? true : false}
                                    onChange={() => setIsShowRoleTags(!isShowRoleTags)}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            {envAsBool('ENABLE_ADDTIONAL_ADVANCED_HIDE_UI_OPTIONS') && canHideBranding(stationDetails.station) && (
                              <UserAccessRestictionsVerifiedContainer>
                                  <UserAccessRestictionsLabel>
                                      Nowhere Branding
                                      <ReactTooltip effect="solid" />
                                      <InactiveInfo data-tip="Show the Nowhere Logo" src={InfoIcon} />
                                  </UserAccessRestictionsLabel>
                                  <OpenCheckbox
                                      errors={errors}
                                      name="isNowhereBrandingHidden"
                                      checked={isNowhereBrandingHidden ? false : true}
                                      onChange={() => setIsNowhereBrandingHidden(!isNowhereBrandingHidden)}
                                  />
                              </UserAccessRestictionsVerifiedContainer>
                            )}

                        </>
                    )}

                    {canAdmin(currentUser) && false && (
                        <OverFlowSpaceContainer>
                            <label htmlFor="uiVersionField">
                                Enter the UI to use for this space
                                <ReactTooltip effect="solid" />
                                <InactiveInfo
                                    data-tip="Will force the space UI to the entered version. Leave blank for the default"
                                    src={InfoIcon}
                                />
                            </label>
                            <ValidatedFormInput
                                labelText=""
                                errors={errors}
                                maxLength={255}
                                name="uiVersionField"
                                onChange={(e: any) => {
                                    setUiVersion(e.target.value);
                                }}
                                type="text"
                                validation={register({})}
                                placeholder={'v1, v2, etc...'}
                                value={uiVersion}
                            />
                        </OverFlowSpaceContainer>
                    )}

                    {(canAccessAdvancedSettings(station) || canAdmin(currentUser)) && (
                        <UserAccessRestictionsVerifiedContainer>
                            <UserAccessRestictionsLabel>
                                Advanced In-World Permissions
                                <ReactTooltip effect="solid" />
                                <InactiveInfo
                                    data-tip="Control what menus & objects are visible/editable for guests & hosts"
                                    src={InfoIcon}
                                />
                            </UserAccessRestictionsLabel>
                            <OpenCheckbox
                                errors={errors}
                                name="isAdvancedPermissionsShown"
                                checked={isAdvancedPermissionsShown ? true : false}
                                onChange={() => setIsAdvancedPermissionsShown(!isAdvancedPermissionsShown)}
                            />
                        </UserAccessRestictionsVerifiedContainer>
                    )}

                    {isAdvancedPermissionsShown && (
                        <>
                            <OverFlowSpaceContainer>
                                <label htmlFor="menuPermissionsSpaces">Spaces</label>
                                <Select
                                    onChange={(value) =>
                                        setMenuPermissions({ ...menuPermissions, Spaces: value as SpaceMenuPermission })
                                    }
                                    value={menuPermissions.Spaces}
                                    options={getSpaceMenuOptions()}
                                    spClassName="noTranslate"
                                />
                            </OverFlowSpaceContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="menuPermissionsEvents">Events</label>
                                <Select
                                    onChange={(value) =>
                                        setMenuPermissions({ ...menuPermissions, Events: value as SpaceMenuPermission })
                                    }
                                    value={menuPermissions.Events}
                                    options={getSpaceMenuOptions()}
                                    spClassName="noTranslate"
                                />
                            </OverFlowSpaceContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="menuPermissionsOrbit">Orbit & Connections</label>
                                <Select
                                    onChange={(value) =>
                                        setMenuPermissions({ ...menuPermissions, Orbit: value as SpaceMenuPermission })
                                    }
                                    value={menuPermissions.Orbit}
                                    options={getSpaceMenuOptions()}
                                    spClassName="noTranslate"
                                />
                            </OverFlowSpaceContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="menuPermissionsSettings">World Settings</label>
                                <Select
                                    onChange={(value) =>
                                        setMenuPermissions({
                                            ...menuPermissions,
                                            Settings: value as SpaceMenuPermission
                                        })
                                    }
                                    value={menuPermissions.Settings}
                                    options={getSpaceMenuOptions()}
                                    spClassName="noTranslate"
                                />
                            </OverFlowSpaceContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="menuPermissionsEditEntities">
                                    Edit Entities
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="Includes Custom Frames, Object Blocks & Portals"
                                        src={InfoIcon}
                                    />
                                </label>
                                <Select
                                    onChange={(value) =>
                                        setMenuPermissions({
                                            ...menuPermissions,
                                            EditEntities: value as SpaceMenuPermission
                                        })
                                    }
                                    value={menuPermissions.EditEntities}
                                    options={getSpaceMenuOptions(['Everyone'])}
                                    spClassName="noTranslate"
                                />
                            </OverFlowSpaceContainer>

                            { envAsBool('ENABLE_ADDTIONAL_ADVANCED_HIDE_UI_OPTIONS') && (
                            <>
                              <OverFlowSpaceContainer>
                                  <label htmlFor="menuPermissionsQuestionAndAnswer">
                                      Q&A
                                      <ReactTooltip effect="solid" />
                                      <InactiveInfo
                                          data-tip="Guests can raise their hand and vote on favorite questions"
                                          src={InfoIcon}
                                      />
                                  </label>
                                  <Select
                                      onChange={(value) =>
                                          setMenuPermissions({
                                              ...menuPermissions,
                                              QuestionAndAnswer: value as SpaceMenuPermission
                                          })
                                      }
                                      value={menuPermissions.QuestionAndAnswer?? 'Everyone'}
                                      options={getSpaceMenuOptions()}
                                      spClassName="noTranslate"
                                  />
                              </OverFlowSpaceContainer>

                               <OverFlowSpaceContainer>
                                  <label htmlFor="menuPermissionsChat">
                                      Chat
                                      <ReactTooltip effect="solid" />
                                      <InactiveInfo
                                          data-tip="Message other players in the space and display messages over your head"
                                          src={InfoIcon}
                                      />
                                  </label>
                                  <Select
                                      onChange={(value) =>
                                          setMenuPermissions({
                                              ...menuPermissions,
                                              Chat: value as SpaceMenuPermission
                                          })
                                      }
                                      value={menuPermissions.Chat?? 'Everyone'}
                                      options={getSpaceMenuOptions()}
                                      spClassName="noTranslate"
                                  />
                              </OverFlowSpaceContainer>

                              <OverFlowSpaceContainer>
                                  <label htmlFor="menuPermissionsPlayerList">
                                      Player List
                                      <ReactTooltip effect="solid" />
                                      <InactiveInfo
                                          data-tip="See who is in the space, contains moderation tools for hosts"
                                          src={InfoIcon}
                                      />
                                  </label>
                                  <Select
                                      onChange={(value) =>
                                          setMenuPermissions({
                                              ...menuPermissions,
                                              PlayerList: value as SpaceMenuPermission
                                          })
                                      }
                                      value={menuPermissions.PlayerList?? 'Everyone'}
                                      options={getSpaceMenuOptions()}
                                      spClassName="noTranslate"
                                  />
                              </OverFlowSpaceContainer>
                            </>
                          )}
                        </>
                    )}

                    {canAccessAdvancedSettings(station) && canEmbed(station) && (
                        <>
                            <Divider />

                            <OverFlowSpaceContainer>
                                <h4>Emded Options</h4>
                            </OverFlowSpaceContainer>
                            <br />
                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Skip AV/Avatar Check
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="The AV/Avatar check will not apear pre world enter"
                                        src={InfoIcon}
                                    />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isSkipWelcome"
                                    checked={embedRules?.isSkipWelcome ? true : false}
                                    onChange={() => {
                                        setEmbedRules({ ...embedRules, isSkipWelcome: !embedRules.isSkipWelcome });
                                    }}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            <UserAccessRestictionsVerifiedContainer>
                                <UserAccessRestictionsLabel>
                                    Embed Only
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="This space will only be accessable via embed"
                                        src={InfoIcon}
                                    />
                                </UserAccessRestictionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isEmbedOnly"
                                    checked={embedRules?.isEmbedOnly ? true : false}
                                    onChange={() => {
                                        setEmbedRules({ ...embedRules, isEmbedOnly: !embedRules.isEmbedOnly });
                                    }}
                                />
                            </UserAccessRestictionsVerifiedContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="requiredHost">
                                    Require Specific Domain Name
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="Only allow embeds from a domain name ie: 'nwr.mysite.com' (use '*' to allow all)"
                                        src={InfoIcon}
                                    />
                                </label>
                                <ValidatedFormInput
                                    labelText=""
                                    errors={errors}
                                    maxLength={1250}
                                    name="Only allow embeds from a domain name ie: 'nwr.mysite.com' (use '*' to allow all)"
                                    onChange={(e: any) => {
                                        setEmbedRules({ ...embedRules, requiredHost: e.target.value });
                                    }}
                                    type="text"
                                    validation={register({})}
                                    value={embedRules.requiredHost}
                                />
                            </OverFlowSpaceContainer>

                            <OverFlowSpaceContainer>
                                <label htmlFor="redirectURL">
                                    Embed redirect
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="Will redirect to this locaiton when embad rules are not met"
                                        src={InfoIcon}
                                    />
                                </label>
                                <ValidatedFormInput
                                    labelText=""
                                    errors={errors}
                                    maxLength={1250}
                                    name="Only allow embeds from a domain name ie: 'nwr.mysite.com' (use '*' to allow all)"
                                    onChange={(e: any) => {
                                        setEmbedRules({ ...embedRules, redirectURL: e.target.value });
                                    }}
                                    type="text"
                                    validation={register({})}
                                    value={embedRules.redirectURL}
                                />
                            </OverFlowSpaceContainer>
                        </>
                    )}

                    {canAccessAdvancedSettings(station) && (
                        <>
                            <Divider />
                            <OverFlowSpaceContainer>
                                <h4>Spectator</h4>
                            </OverFlowSpaceContainer>
                            <br />
                            <ModeOptionsContainer>
                                <ModeOptionsLabel>
                                    Enable
                                    <ReactTooltip effect="solid" />
                                    <InactiveInfo
                                        data-tip="In Spectator mode, non-participating guests can silently observe from a fixed camera point"
                                        src={InfoIcon}
                                    />
                                </ModeOptionsLabel>
                                <OpenCheckbox
                                    errors={errors}
                                    name="isEnabled"
                                    checked={modeSettings?.spectate?.isEnabled}
                                    onChange={() => {
                                        updateModeSettings(
                                            ['spectate', 'isEnabled'],
                                            !modeSettings?.spectate.isEnabled
                                        );
                                    }}
                                />
                            </ModeOptionsContainer>

                            {modeSettings?.spectate?.isEnabled && (
                                <>
                                    <ModeOptionsContainer>
                                        <ModeOptionsLabel>
                                            Show Invite button
                                            <ReactTooltip effect="solid" />
                                            <InactiveInfo
                                                data-tip="The invite button lets Spectators invite others"
                                                src={InfoIcon}
                                            />
                                        </ModeOptionsLabel>
                                        <OpenCheckbox
                                            errors={errors}
                                            name="isInviteUiEnabled"
                                            checked={modeSettings?.spectate?.isInviteUiEnabled}
                                            onChange={() => {
                                                updateModeSettings(
                                                    ['spectate', 'isInviteUiEnabled'],
                                                    !modeSettings?.spectate.isInviteUiEnabled
                                                );
                                            }}
                                        />
                                    </ModeOptionsContainer>

                                    <ModeOptionsContainer>
                                        <ModeOptionsLabel>
                                            Lock Viewpoint
                                            <ReactTooltip effect="solid" />
                                            <InactiveInfo
                                                data-tip="Sets Spectator’s fixed camera coordinates. To change, launch space with a Host account, position view & press ctrl-k. Hint: try using camera in Fly mode"
                                                src={InfoIcon}
                                            />
                                        </ModeOptionsLabel>
                                        <OpenCheckbox
                                            errors={errors}
                                            name="isLockViewpointEnabled"
                                            checked={modeSettings?.spectate?.isLockViewpointEnabled}
                                            onChange={() => {
                                                updateModeSettings(
                                                    ['spectate', 'isLockViewpointEnabled'],
                                                    !modeSettings?.spectate.isLockViewpointEnabled
                                                );
                                            }}
                                        />
                                    </ModeOptionsContainer>

                                    {spectatePoint && (
                                        <CamCords>
                                            X: {round(spectatePoint?.X)}, Y: {round(spectatePoint?.Y)}, Z:{' '}
                                            {round(spectatePoint?.Z)}, RX: {round(spectatePoint?.RX)}, RY:{' '}
                                            {round(spectatePoint?.RY)}, RZ: {round(spectatePoint?.RZ)}
                                        </CamCords>
                                    )}

                                    {!modeSettings?.spectate?.isLockViewpointEnabled && (
                                        <>
                                            {!'COMMENTED OUT' && (
                                                <ModeOptionsCamCordsContainer>
                                                    <label htmlFor="FixedCameraCords">
                                                        Fixed Camera Coordinates
                                                        <ReactTooltip effect="solid" />
                                                        <InactiveInfo
                                                            data-tip="Sets Spectator’s fixed camera coordinates. To change, launch space with a Host account, position view & press ctrl-k. Hint: try using camera in Fly mode"
                                                            src={InfoIcon}
                                                        />
                                                    </label>
                                                    <div></div>
                                                </ModeOptionsCamCordsContainer>
                                            )}
                                            <CamCordsText>Set in-world press ctrl-k</CamCordsText>
                                        </>
                                    )}

                                    {!'COMMENTED OUT' && (
                                        <>
                                            <div>
                                                Is Preview Mode:{' '}
                                                {(modeSettings?.spectate?.activeMode === 'preview').toString()}
                                                <br />
                                            </div>

                                            <ModeToggleContainer>
                                                <ModeLabel>Mode</ModeLabel>
                                                <LabeledToggle
                                                    leftLabel="preview"
                                                    rightLabel="interactive"
                                                    value={modeSettings?.spectate?.activeMode === 'preview'}
                                                    changeHandler={(value) =>
                                                        updateModeSettings(
                                                            ['spectate', 'activeMode'],
                                                            value ? 'preview' : 'interactive'
                                                        )
                                                    }
                                                />
                                            </ModeToggleContainer>
                                        </>
                                    )}

                                    <OverFlowSpaceContainer>
                                        <label htmlFor="Mode">
                                            Mode
                                            <ReactTooltip effect="solid" />
                                            <InactiveInfo
                                                data-tip="Preview blocks Spectators from sending messages, questions & reactions"
                                                src={InfoIcon}
                                            />
                                        </label>
                                        <Select
                                            onChange={(value) => updateModeSettings(['spectate', 'activeMode'], value)}
                                            value={modeSettings?.spectate?.activeMode}
                                            options={[
                                                { label: 'Preview', value: 'preview' },
                                                { label: 'Interactive', value: 'interactive' }
                                            ]}
                                            spClassName="noTranslate"
                                        />
                                    </OverFlowSpaceContainer>

                                    <OverFlowSpaceContainer>
                                        <label htmlFor="conversionButtonURL">
                                            Conversion button
                                            <ReactTooltip effect="solid" />
                                            <InactiveInfo
                                                data-tip="Assign button destination for Spectators. Use with an Embed, or add spoce Invite"
                                                src={InfoIcon}
                                            />
                                        </label>
                                        <ValidatedFormInput
                                            labelText=""
                                            errors={errors}
                                            maxLength={1250}
                                            name="conversionButtonURL"
                                            onChange={(e: any) => {
                                                updateModeSettings(
                                                    [
                                                        'spectate',
                                                        modeSettings.spectate.activeMode,
                                                        'conversionButtonURL'
                                                    ],
                                                    e.target.value
                                                );
                                            }}
                                            type="text"
                                            placeholder="Adds Space invite if blank"
                                            validation={register({})}
                                            value={
                                                modeSettings.spectate[modeSettings.spectate.activeMode]
                                                    .conversionButtonURL ?? ''
                                            }
                                        />
                                    </OverFlowSpaceContainer>

                                    <SpectateSmallButtonWrapper>
                                        <SmallButton
                                            onClick={(event) => {
                                                applySpaceInviteToActiveSpecate();
                                                event?.preventDefault();
                                            }}
                                        >
                                            Add Space Invite
                                        </SmallButton>
                                    </SpectateSmallButtonWrapper>

                                    <OverFlowSpaceContainer>
                                        <label htmlFor="conversionButtonText">Button Text</label>
                                        <ValidatedFormInput
                                            labelText=""
                                            errors={errors}
                                            maxLength={15}
                                            name="conversionButtonText"
                                            onChange={(e: any) => {
                                                updateModeSettings(
                                                    [
                                                        'spectate',
                                                        modeSettings.spectate.activeMode,
                                                        'conversionButtonText'
                                                    ],
                                                    e.target.value
                                                );
                                            }}
                                            type="text"
                                            validation={register({})}
                                            value={
                                                modeSettings.spectate[modeSettings.spectate.activeMode]
                                                    .conversionButtonText ?? ''
                                            }
                                        />
                                    </OverFlowSpaceContainer>

                                    <OverFlowSpaceContainer>
                                        <label htmlFor="modalTitle">
                                            Modal Title
                                            <ReactTooltip effect="solid" />
                                            <InactiveInfo
                                                data-tip="This overlay is always shown for Spectators"
                                                src={InfoIcon}
                                            />
                                        </label>
                                        <ValidatedFormInput
                                            labelText=""
                                            errors={errors}
                                            maxLength={15}
                                            name="modalTitle"
                                            onChange={(e: any) => {
                                                updateModeSettings(
                                                    ['spectate', modeSettings.spectate.activeMode, 'modalTitle'],
                                                    e.target.value
                                                );
                                            }}
                                            type="text"
                                            validation={register({})}
                                            value={
                                                modeSettings.spectate[modeSettings.spectate.activeMode].modalTitle ?? ''
                                            }
                                        />
                                    </OverFlowSpaceContainer>

                                    <OverFlowSpaceContainer>
                                        <label htmlFor="modalDescription">Modal Description</label>
                                        <ValidatedFormInput
                                            labelText=""
                                            errors={errors}
                                            maxLength={56}
                                            name="modalDescription"
                                            onChange={(e: any) => {
                                                updateModeSettings(
                                                    ['spectate', modeSettings.spectate.activeMode, 'modalDescription'],
                                                    e.target.value
                                                );
                                            }}
                                            type="text"
                                            validation={register({})}
                                            value={
                                                modeSettings.spectate[modeSettings.spectate.activeMode]
                                                    .modalDescription ?? ''
                                            }
                                        />
                                    </OverFlowSpaceContainer>

                                    <SpectateSmallButtonWrapper>
                                        <SmallButton
                                            onClick={(event) => {
                                                resetSpectate();
                                                event?.preventDefault();
                                            }}
                                        >
                                            Reset Default Info
                                        </SmallButton>
                                    </SpectateSmallButtonWrapper>
                                </>
                            )}
                        </>
                    )}
                </LeftColumn>

                <RightColumn>
                    <ValidatedFormInput
                        labelText="Space Name*"
                        errors={errors}
                        maxLength={35}
                        name="title"
                        onChange={(e: any) => setSpaceName(e.target.value)}
                        type="text"
                        validation={register({ required: 'Space name is required' })}
                        value={title}
                    />

                    <label htmlFor="Environment">
                        Choose Environment
                        {showHelp && (
                            <HelpText
                                label={envHelpText}
                                noWrap={false}
                                style={envHelpTextStyle}
                                innerStyle={envHelpTextInnerStyle}
                            />
                        )}
                    </label>
                    <EnvironmentSelect
                        stationId={stationId}
                        environmentId={environmentId}
                        setEnvironmentId={setEnvironmentId}
                        spaceId={space?.id}
                    ></EnvironmentSelect>

                    <ValidatedFormInput
                        labelText="Subtitle (35 characters)"
                        errors={errors}
                        maxLength={35}
                        name="subtitle"
                        onChange={(e: any) => setSubtitle(e.target.value)}
                        type="text"
                        validation={register({})}
                        value={subtitle}
                    />

                    <ValidatedFormInput
                        labelText="Full Description"
                        errors={errors}
                        maxLength={1250}
                        name="description"
                        onChange={(e: any) => setDescription(e.target.value)}
                        type="textarea"
                        style={{ height: '200px' }}
                        validation={register({})}
                        value={description}
                    />

                    <TippWrapper>
                        <h3>Tip Settings</h3>
                        <GreyishBodyText>If and where to receive tips</GreyishBodyText>
                        <br />
                        <CheckboxWrapper>
                            <OpenCheckbox
                                errors={errors}
                                name="tippingOptIn"
                                checked={isTipOptIn ? true : false}
                                onChange={() => toggleTipping(!isTipOptIn)}
                            />
                            I want this space to receive tips
                        </CheckboxWrapper>

                        {isTipOptIn ? (
                            <>
                                <ValidatedFormInput
                                    labelText="Tip Email Address (PayPal)"
                                    type="text"
                                    errors={errors}
                                    validation={register({
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address'
                                        }
                                    })}
                                    maxLength={256}
                                    name="tippingEmail"
                                    value={tippingEmail ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTippingEmail(e.target.value);
                                    }}
                                />
                                <GreyishBodyText>Tip email address will be shown to Nowhere users.</GreyishBodyText>
                            </>
                        ) : null}
                        <br />
                        <label htmlFor="tipsReceived">
                            Total Tips Received
                            <ReactTooltip effect="solid" />
                            <InactiveInfo
                                data-tip="This is meant to be an estimate, and may not be an accurate reflection of total funds received."
                                src={InfoIcon}
                            />
                        </label>
                        <h5 style={{ marginBottom: '30px' }}>
                            <b>{USDDollarFormat(usdTipHistory?.summery?.totalAmount ?? 0)}</b>
                        </h5>
                    </TippWrapper>

                    {canAccessAdvancedSettings(station) && hasOwnerRole(station, currentUser) && (
                        <>
                            <div>
                                <ValidatedFormInput
                                    labelText="EOS ID :: Receive Payments In Crypto"
                                    errors={errors}
                                    maxLength={255}
                                    name="walletsEos"
                                    onChange={(e: any) => {
                                        setWallets({ ...wallets, ...{ eos: e.target.value } });
                                    }}
                                    type="text"
                                    validation={register({})}
                                    value={wallets?.eos ?? ''}
                                    placeholder={'Enter your EOS ID'}
                                />
                                {showHelp && (
                                    <HelpText
                                        label={discordHelpText}
                                        noWrap={false}
                                        innerStyle={discordHelpTextInnerStyle}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {canAccessAdvancedSettings(station) && (
                        <>
                            <br />
                            <label htmlFor="tipsReceived">
                                Total UPX Received
                                <ReactTooltip effect="solid" />
                                <InactiveInfo
                                    data-tip="This is meant to be an estimate, and may not be an accurate reflection of total funds received."
                                    src={InfoIcon}
                                />
                            </label>
                            <h5 style={{ marginBottom: '30px' }}>
                                <b>
                                    {USDDollarFormat(upxTipHistory?.summery?.totalAmount ?? 0).replace('$', '') +
                                        ' UPX'}
                                </b>
                            </h5>
                        </>
                    )}

                    <div>
                        <ValidatedFormInput
                            labelElement={DiscordLabelElement()}
                            errors={errors}
                            maxLength={3000}
                            name="discordWebhook"
                            onChange={(e: any) => {
                                setDiscordWebhook(e.target.value);
                            }}
                            type="text"
                            validation={register({})}
                            value={discordWebhook}
                            placeholder={'Enter Discord Webhook URL man'}
                        />
                        {showHelp && (
                            <HelpText label={discordHelpText} noWrap={false} innerStyle={discordHelpTextInnerStyle} />
                        )}
                    </div>
                    <br />

                    <CheckboxWrapper>
                        <OpenCheckbox
                            errors={errors}
                            name="addSpaceGate"
                            checked={addSpaceGate ? true : false}
                            onChange={() => {
                                if (!addMusicGate && !addCryptoGate && !addWhitelistGate) setAddCryptoGate(true);
                                setAddSpaceGate(!addSpaceGate);
                            }}
                        />
                        Gate Space Access
                    </CheckboxWrapper>

                    {addSpaceGate ? (

                        <div>

                          <InputLabel htmlFor="chooseGateType">Choose Gate Type</InputLabel>
                          <Select
                              onChange={(value) => {
                                  const val = parseInt(value);
                                  setAddCryptoGate(false);
                                  setAddMusicGate(false);
                                  setAddWhitelistGate(false);
                                  if(val === 1) setAddCryptoGate(true);
                                  if(val === 2) setAddMusicGate(true);
                                  if(val === 3) setAddWhitelistGate(true);
                              }}
                              value={getSelectedGateOption()}
                              options={gateTypeOptions}
                          />
                          <br />
                        </div>
                    ) : null}

                    {addSpaceGate && addWhitelistGate ? (
                      <>
                          <h2>Whitelist Gate Policy</h2>
                          <br />
                          <p>
                              Require all guests to be present on the whitelist.
                          </p>
                          <br />

                          <ValidatedFormInput
                              labelText="Gate Display Message"
                              errors={errors}
                              maxLength={1250}
                              name="gateWhitelistDisplayMessage"
                              onChange={(e: any) => setGateWhitelistDisplayMessage(e.target.value)}
                              type="text"
                              validation={register({})}
                              value={gateWhitelistDisplayMessage}
                              placeholder={'Request access from the space owner'}
                          />

                         <InputLabel htmlFor="chooseWhitelistType">Whitelist Type</InputLabel>
                         <Select
                              onChange={(value) => {
                                 setGateWhitelistProvider(value)
                              }}
                              value={gateWhitelistProvider}
                              options={gateWhitelistProviderOptions}
                         />

                         <InputLabel htmlFor="gateWhitelistList">Comma separated list</InputLabel>
                         <ValidatedFormInput
                            type="textarea"
                            className="w-input"
                            maxLength={256000}
                            name="gateWhitelistList"
                            errors={errors}
                            placeholder=''
                            validation={register({})}
                            value={gateWhitelistListString}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGateWhitelistListString(e.target.value)}
                            style={{ margin: '0 0px 0 0', width: '500px', height: '150px' }}
                        />

                        <CheckboxWrapper>
                          <OpenCheckbox
                              errors={errors}
                              name="gateWhitelistApplyToHosts"
                              checked={gateWhitelistApplyToHosts ? true : false}
                              onChange={() => { setGateWhitelistApplyToHosts(!gateWhitelistApplyToHosts); }}
                          />
                          Apply gate to hosts and managers
                      </CheckboxWrapper>
                      </>
                    ) : null}

                    {addSpaceGate && addCryptoGate ? (
                        <>
                            <h2>Crypto Gate Policy</h2>
                            <br />
                            <p>
                                Require all guests to hold specified crypto assets. Hosts can always access the space
                                without satisfying gate conditions.
                            </p>
                            <ButtonContainer>
                                <TinyButton onClick={showLitModal} type="button">
                                    {selectedConditions ? 'Update Gate Policy' : 'Create Gate Policy'}
                                </TinyButton>

                                <LitModal />

                                {selectedConditions && debugCryptoGate ? (
                                    <>
                                        <h3>Selected conditions: </h3>
                                        <pre>{JSON.stringify(selectedConditions, null, 2)}</pre>
                                    </>
                                ) : null}
                            </ButtonContainer>
                            <br />
                            <div>
                                <InputLabel htmlFor="userTypeSelect">Check User Asset Holdings</InputLabel>
                                <Select
                                    onChange={(value) => setGateExpiration(value)}
                                    value={gateExpiration}
                                    options={gateExpirationValues}
                                />
                            </div>
                            <ValidatedFormInput
                                labelText="Asset Gate Display Message"
                                errors={errors}
                                maxLength={1250}
                                name="gateDisplayMessage"
                                onChange={(e: any) => setGateCryptoDisplayMessage(e.target.value)}
                                type="text"
                                validation={register({})}
                                value={gateCryptoDisplayMessage}
                                placeholder={'You must have X# of $Y tokens to enter this space'}
                            />
                        </>
                    ) : null}

                    {!ENABLE_SPOTIFY_GATE && addSpaceGate && addMusicGate ? (
                        <>
                            <h2>Comming Soon...</h2>
                        </>
                    ) : null}

                    {ENABLE_SPOTIFY_GATE && addSpaceGate && addMusicGate ? (
                        <>
                            <h2>Spotify Gate Policy</h2>
                            <br />
                            <p>
                                Asks all guests to follow or save an artist, album, track, playlist or user. Hosts can
                                always access the space without satisfying gate conditions.
                            </p>
                            <br />
                            <div>
                                <InputLabel htmlFor="spotifyFollowTypeSelect">To Access, Guests Must</InputLabel>
                                <Select
                                    onChange={(value) => {
                                        setGatePolicyChanged(true);
                                        setGateSpotifyFollowType(value);
                                        updateSpotifyLinks(undefined, value);
                                    }}
                                    value={gateSpotifyFollowType}
                                    options={spotifyFollowOptions}
                                />
                            </div>
                            <ValidatedFormInput
                                labelText="Spotify Asset ID"
                                errors={errors}
                                maxLength={1250}
                                name="spotifyAssetId"
                                onChange={(e: any) => {
                                    setGatePolicyChanged(true);
                                    setGateSpotifyFollowId(e.target.value);
                                }}
                                onBlur={updateSpotifyLinks}
                                type="text"
                                validation={register({})}
                                value={gateSpotifyFollowId}
                                placeholder={'You must enter the spotify asset ID'}
                                interactiveInfo={{
                                    dataTip:
                                        'To find the ID in Spotify App, right click > "Share" > "Copy link", and find the string after the last "/" and before the "?"',
                                    infoIcon: InfoIcon
                                }}
                            />
                            <div>
                                <InputLabel htmlFor="assetLinks">Confirm asset links are correct</InputLabel>
                                {spotifyLinks.map((link, index) => (
                                    <div key={index}>
                                        <a href={link} target="new">
                                            {link}
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <br />
                            <div>
                                <InputLabel htmlFor="userTypeSelect">Check User Spotify</InputLabel>
                                <Select
                                    onChange={(value) => {
                                        setGatePolicyChanged(true);
                                        setGateExpiration(value);
                                    }}
                                    value={gateExpiration}
                                    options={gateExpirationValues}
                                />
                            </div>

                            <InputLabel htmlFor="gateDisplayMessage">Spotify Gate Display Message</InputLabel>
                            <div>{gateSpotifyDisplayMessage}</div>
                        </>
                    ) : null}

                    <ButtonContainer>
                        <WhiteLinkText onClick={() => cancel(space?.id)}>Cancel</WhiteLinkText>
                        <AuthButton id="signupButton" disabled={uploading || submitting}>
                            {uploading ? 'Uploading...' : space ? 'Update Space' : 'Create Space'}
                        </AuthButton>
                    </ButtonContainer>
                </RightColumn>
            </FormColumns>
        </Form>
    );
};

const CapacityLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`;

const EmbedLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`;

const OverFlowSpaceContainer = styled.div`
    min-width: 300px;
    text-align: right;
    margin-right: 20px;
`;

const FeaturedCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 20px;
`;

const FeaturedLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 15px;
`;

const UserAccessRestictionsAnonContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 5px;

    label {
        margin-bottom: 0;
    }
`;

const UserAccessRestictionsVerifiedContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-right: 5px;

    label {
        margin-bottom: 0;
    }
`;

const SpectateSmallButtonWrapper = styled.div`
    margin-right: 20px;
`;

const UserAccessRestictionsLabel = styled.div`
    margin-right: 15px;
`;

const ModeOptionsContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-right: 5px;

    label {
        margin-bottom: 0;
    }
`;

const ModeOptionsCamCordsContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-right: 20px;

    label {
        margin-bottom: 0;
    }
`;

const CamCordsText = styled.div`
    margin-right: 20px;
    margin-top: -10px;
    margin-bottom: 15px;
`;

const CamCords = styled.div`
    margin-right: 20px;
    margin-top: -10px;
    margin-bottom: 15px;
    text-align: right;
`;

const ModeOptionsLabel = styled.div`
    margin-right: 15px;
`;

const TippWrapper = styled.div`
    marginbottom: '30px';
    width: '100%';
`;

const PrivacyToggleContainer = styled.div`
    display: flex;
    margin-right: 20px;
    padding-top: 0px;
    margin-bottom: 20px;
`;

const PrivacyLabel = styled.div`
    color: var(--off-white);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
    padding-top: 5px;
    letter-spacing: 1.17px;
`;

const ModeToggleContainer = styled.div`
    display: flex;
    margin-right: 20px;
    padding-top: 0px;
    margin-bottom: 20px;
`;

const ModeLabel = styled.div`
    color: var(--off-white);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
    padding-top: 5px;
    letter-spacing: 1.17px;
`;

const InactiveInfo = styled.img`
    margin-left: 10px;
    margin-top: -4px;
    fill: #ffffff;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 30px;
    > * {
        margin-right: 60px;
    }
`;

const Form = styled.form`
    margin: 0 auto;
    display: flex;
    justify-content: center;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormColumns = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 60px;
    > * {
        margin-bottom: 80px;
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        > * {
            &:not(:last-child) {
                margin-right: 60px;
            }
        }
    }
`;

const CapacityInputContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 0 20px 0;
    > * {
        &:first-child {
            margin-right: 15px;
        }
    }
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
    }
`;

export default SpaceForm;
