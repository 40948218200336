const firebaseConfig = {
  apiKey: "AIzaSyBd664BqW9QpVV1ei-jKWwOUWcM4vksM58",
  authDomain: "nwr-dev.firebaseapp.com",
  projectId: "nwr-dev",
  storageBucket: "nwr-dev.appspot.com",
  messagingSenderId: "11141545108",
  appId: "1:11141545108:web:d439ccd34d481b61770881",
  measurementId: "G-10PQ51P2Q6"
};

export default firebaseConfig;

